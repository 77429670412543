<template>
  <v-container class="pa-0" style="height: 100%">
    <googlemaps-map
      class="map"
      :center.sync="center"
      :zoom.sync="zoom"
      @idle="onIdle"
      @click="onMapClick"
    >
      <googlemaps-marker
        :title="markedLocation.name"
        :position="markedLocation.location"
      ></googlemaps-marker>
      <!-- User Position -->
      <googlemaps-user-position @update:position="setUserPosition" />
    </googlemaps-map>
    <!-- Places list -->
    <googlemaps-nearby-places
      class="results-pane"
      ref="results"
      :request="nearbyRequest"
    >
      <template slot-scope="props">
        <v-dialog
          v-model="nearbyPlaces"
          fullscreen
          transition="dialog-bottom-transition"
          :overlay="false"
          scrollable
        >
          <v-card tile>
            <v-toolbar class="primary">
              <v-toolbar-title class="white--text"
                >Nearby Places</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-btn icon @click.native="nearbyPlaces = !nearbyPlaces">
                <v-icon class="white--text">mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-list>
              <v-list-item
                v-for="result of props.results"
                :key="result.name"
                @click.stop="
                  setPlaceCenter(result), (nearbyPlaces = !nearbyPlaces)
                "
              >
                <v-list-item-avatar>
                  <img
                    v-if="result.photos"
                    :src="
                      result.photos[0].getUrl({ maxWidth: 80, maxHeight: 80 })
                    "
                  />
                  <v-icon v-else>mdi-map-marker</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ result.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-dialog>
      </template>
    </googlemaps-nearby-places>
    <googlemaps-place-details
      class="results-pane"
      ref="details"
      :request="placeDetailsRequest"
      @results="onResults"
    >
      <template slot-scope="props">
        <v-dialog v-if="placeDetails" v-model="placeDetails" max-width="500px">
          <v-card v-if="props.results">
            <v-carousel
              cycle
              v-if="props.results.photos"
              hide-delimiters
              :hide-controls="props.results.photos.length === 1"
              height="300"
            >
              <v-carousel-item
                v-for="(item, i) in props.results.photos"
                :key="i"
                :src="item.getUrl({ maxWidth: 500, maxHeight: 500 })"
              ></v-carousel-item>
            </v-carousel>
            <v-card-title>
              {{ props.results.name }}
            </v-card-title>
            <v-card-text>
              <div
                class="text-overline"
                v-if="
                  props.results.opening_hours &&
                  props.results.opening_hours.open_now
                "
              >
                Open now
              </div>
              <div>{{ props.results.formatted_address }}</div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions v-if="props.results.website">
              <v-spacer></v-spacer>
              <v-btn small icon @click.native="goTo(props.results.website)">
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </googlemaps-place-details>
    <v-fab-transition>
      <v-btn
        class="mb-10"
        absolute
        fab
        large
        bottom
        left
        color="primary"
        @click.native.stop="nearbyPlaces = !nearbyPlaces"
      >
        <v-icon>mdi-map-marker</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      center: {
        lat: 47.2736,
        lng: 16.0843,
      },
      userPosition: undefined,
      searchBounds: undefined,
      zoom: 12,
      nearbyPlaces: false,
      markedLocation: {
        name: undefined,
        location: undefined,
      },
      placeId: undefined,
      placeDetails: false,
    };
  },
  computed: {
    nearbyRequest() {
      if (this.searchBounds) {
        return {
          bounds: this.searchBounds,
        };
      }
    },
    placeDetailsRequest() {
      if (this.placeId) {
        return {
          placeId: this.placeId,
        };
      }
    },
  },
  methods: {
    centerOnUser() {
      if (this.userPosition) {
        (this.center = this.userPosition),
          (this.markedLocation.location = this.userPosition);
        this.markedLocation.name = "Current Location";
      }
    },
    setUserPosition(position) {
      this.userPosition = position;
      this.markedLocation.location = this.userPosition;
      this.markedLocation.name = "Current Location";
    },
    onIdle(map) {
      this.searchBounds = map.getBounds();
      this.$refs.results.$el.scrollTop = 0;
    },
    setPlaceCenter(place) {
      this.placeId = place.place_id;
      this.placeDetails = !this.placeDetails;
    },
    onMapClick(event) {
      event.stop();
      this.center = event.latLng;
      this.markedLocation.location = this.center;
      this.markedLocation.name = "Current Location";
    },
    goTo(link) {
      if (link) {
        window.location.href = link;
      }
    },
  },
};
</script>

<style lang="scss">
.map {
  height: 100%;
}
</style>
